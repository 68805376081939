import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default.min';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory';

class GallerySection {
  constructor(el) {
    this.el = el;
    this.dom = {
      photoswipe: document.querySelector('.pswp'),
      imageItems: document.querySelectorAll('.GallerySection__image'),
      imageElements: document.querySelectorAll('.GallerySection__image img')
    };
    this.gallery = null;

    this.init();
  }

  init = () => {
    this.dom.imageItems.forEach((image) => {
      image.addEventListener('click', this.handleImageClick);
    });
  };

  handleImageClick = (e) => {
    const items = [...this.dom.imageElements].map((image) => {
      return {
        src: image.currentSrc || image.src,
        w: image.naturalWidth,
        h: image.naturalHeight
      };
    });

    this.gallery = new PhotoSwipe(this.dom.photoswipe, PhotoSwipeUI, items, {
      history: false,
      closeEl:true,
      captionEl: true,
      fullscreenEl: true,
      zoomEl: true,
      shareEl: false,
      counterEl: true,
      arrowEl: true,
      preloaderEl: true,
    });
    this.gallery.init();
  };
}

RuntimeComponentFactory.add('GallerySection', GallerySection);

