import RuntimeComponentFactory from './factory/RuntimeComponentFactory';

import './module/header';
import './module/gallery';

/*
* Initialize registered components
*/
const modules = [];
Object.entries(RuntimeComponentFactory.getDictionary()).map(([moduleId, moduleClassDefinition]) => {
  document.querySelectorAll(`.${moduleId}`).forEach((element) => {
    modules.push(new moduleClassDefinition(element));
  });
});
