import {disableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';
import {gsap, Power2} from 'gsap';
import RuntimeComponentFactory from '../factory/RuntimeComponentFactory';

class Header {
  constructor(el) {
    this.el = el;

    document.getElementById('navbar-toggler').addEventListener('click', function () {
      const overlay = document.getElementById(this.getAttribute('data-target'));
      const menuItems = overlay.querySelectorAll('ul > li');

      if (this.classList.contains('active')) {
        //close
        clearAllBodyScrollLocks();

        const tl = gsap.timeline({
          onComplete: () => {
            gsap.set(menuItems, {clearProps: 'all'});
            this.classList.remove('active');
            overlay.classList.remove('show');
          }
        });

        tl.to(menuItems, {
          duration: 0.3,
          stagger: 0.05,
          y: 30,
          opacity: 0,
          transformOrigin: 'top',
          ease: Power2.easeOut
        });
        tl.to(overlay, {
          duration: 0.3,
          scaleY: 0,
          transformOrigin: 'top',
          clearProps: 'all',
          ease: Power2.easeOut
        });

      } else {
        //open
        this.classList.add('active');
        overlay.classList.add('show');
        disableBodyScroll(overlay);

        const tl = gsap.timeline();

        tl.from(overlay, {
          duration: 0.3,
          scaleY: 0,
          transformOrigin: 'top',
          clearProps: 'all',
          ease: Power2.easeOut
        });
        tl.from(menuItems, {
          duration: 0.3,
          stagger: 0.05,
          y: 30,
          opacity: 0,
          transformOrigin: 'top',
          clearProps: 'all',
          ease: Power2.easeOut
        });
      }

    });
  }
}

RuntimeComponentFactory.add('Header', Header);

